import React, { useState } from "react";
import {
  Col,
  Button,
  Modal,
  ModalBody,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";
import ReactSelect from "../../Components/Common/ReactSelect";

// Formik validation
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { addUserAPI, updateUserAPI } from "../../helpers/backend_helper";
import { toast } from "react-toastify";

const AddUser = ({ user = {}, isOpen, setIsOpen, loadCustomerData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);

  const permissions = JSON.parse(sessionStorage.getItem("permissions"));


  const onSubmit = async (values) => {
    console.log(values);
    setIsLoading(true);

    try {
      if (user?.username) {
        await updateUserAPI(values, values.username);
      } else {
        await addUserAPI(values);
      }
      loadCustomerData();
      setIsOpen(false);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("error", err);
    }
  };

  const onClose = (formik) => {
    formik.resetForm();
    // resetAsset(null);
    setIsOpen(false);
  };


  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen();
      }}
    >
      {/* <ModalHeader> */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          username: user?.username || "",
          email: user?.email || "",
          password: "",
          phoneNumber: user?.phoneNumber || "",
          company: user?.company || "",
          role: user?.role || "customer",

        }}
        validationSchema={Yup.object({
          username: Yup.string().required("Please Enter Username"),
          email: Yup.string().email("Please Enter Correct Email"),
          password: !user?.username
            ? Yup.string().required("Please Enter Password")
            : Yup.string(),
          company: Yup.string().required("Please Enter Company"),
          role: Yup.string().required("Please Select Role"),
          phoneNumber: Yup.string(),
        })}
        onSubmit={async (values) => {
          onSubmit(values);
        }}
      >
        {(validation) => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <h5 className="modal-title">
                {user?.username ? "Edit" : "Add"} User
              </h5>
              <Button
                type="button"
                onClick={() => onClose(validation)}
                className="btn-close"
                aria-label="Close"
              ></Button>
            </div>
            {/* </ModalHeader> */}
            <ModalBody>
              <Form>
                <div className="row g-3">
                  <Col xxl={12}>
                    <div>
                      <Label htmlFor="username" className="form-label">
                        Username<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="username"
                        type="text"
                        className="form-control"
                        placeholder="Enter Username"
                        id="username"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ""}
                        invalid={
                          validation.touched.username &&
                          validation.errors.username
                            ? true
                            : false
                        }
                        readOnly={user?.username}
                      />
                      {validation.touched.username &&
                      validation.errors.username ? (
                        <FormFeedback type="invalid">
                          {validation.errors.username}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xxl={12}>
                    <div>
                      <Label htmlFor="email" className="form-label">
                        Email
                      </Label>
                      <Input
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Enter Username"
                        id="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  {!user?.username && (
                    <Col xxl={12}>
                      <div className="">
                        <Label className="form-label" htmlFor="password-input">
                          Password<span className="text-danger">*</span>
                        </Label>
                        <div className="position-relative auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={passwordShow ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                          <button
                            onClick={() => setPasswordShow(!passwordShow)}
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>
                    </Col>
                  )}
                  <Col xxl={12}>
                    <div>
                      <Label htmlFor="company" className="form-label">
                        Company<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="company"
                        type="text"
                        className="form-control"
                        placeholder="Enter Company"
                        id="company"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.company || ""}
                        invalid={
                          validation.touched.company &&
                          validation.errors.company
                            ? true
                            : false
                        }
                      />
                      {validation.touched.company &&
                      validation.errors.company ? (
                        <FormFeedback type="invalid">
                          {validation.errors.company}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xxl={12}>
                    <div>
                      <Label htmlFor="phoneNumber" className="form-label">
                        Phone Number
                      </Label>
                      <Input
                        name="phoneNumber"
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone Number"
                        id="phoneNumber"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.phoneNumber || ""}
                        invalid={
                          validation.touched.phoneNumber &&
                          validation.errors.phoneNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.phoneNumber &&
                      validation.errors.phoneNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.phoneNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xxl={12}>
                    <div>
                      <ReactSelect
                        labelName="Role"
                        options={[
                          { label: "Employee", value: "employee" },
                          { label: "Customer", value: "customer" },
                        ]}
                        optionLabel="label"
                        optionValue="value"
                        formikObj={validation}
                        id="role"
                        placeholder="Select Role"
                        isReq
                      />

                      {validation.touched.role && validation.errors.role ? (
                        <FormFeedback type="invalid">
                          {validation.errors.role}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-end">
                      <Button color="light" onClick={() => onClose(validation)}>
                        Close
                      </Button>
                      <Button color="primary" disabled={isLoading || !permissions?.users?.includes("update")}                      >
                        {isLoading
                          ? user?.username
                            ? "Editing..."
                            : "Adding ..."
                          : user?.username
                          ? "Edit User"
                          : "Add User"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </ModalBody>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default AddUser;
