import React from "react";
import { Col, Button, Modal, ModalBody, Row } from "reactstrap";

const CUSTOMER_DATA = [
  {
    label: "ID",
    value: "id",
  },
  { label: "Name", value: "name" },
  { label: "Contact", value: "contact" },
  { label: "Phone Number", value: "phoneNumber" },
  { label: "Fax Number", value: "faxNumber" },
  { label: "Email", value: "email" },
  { label: "Website", value: "website" },
  { label: "Physical Address", value: "physicalAddress" },
  { label: "Mailing Address", value: "mailingAddress" },
  { label: "Notes", value: "notes" },
  { label: "Storage Rate", value: "storageRate" },
  { label: "Lien Fee", value: "lienFee" },
  { label: "Advert Fee", value: "advertFee" },
  { label: "Heavy Tow Fee", value: "heavyTowFee" },
  { label: "Watercraft Fee", value: "watercraftFee" },
  { label: "Auction Day", value: "auctionDay" },
  { label: "Lien Credit", value: "lienCredit" },
];

const CustomerDetails = ({
  customer = {},
  isOpen,
  setIsOpen,
  onEditCustomer,
  permissions,
}) => {
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen();
      }}
    >
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <h5 className="modal-title">Customer Details</h5>
          <Button
            type="button"
            onClick={() => onClose()}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody className="pt-0">
          {permissions?.customers?.includes("update") && (
            <div className="d-flex justify-content-end">
              <Button
                className="ml-auto"
                color="success"
                onClick={onEditCustomer}
              >
                Edit Customer
              </Button>
            </div>
          )}
          <Row>
            <Col xs={4} sm={4} className="">
              {CUSTOMER_DATA.map((d, i) => (
                <p key={i} className="font-weight-bold">
                  {d.label}
                </p>
              ))}
            </Col>
            <Col xs={8} sm={8}>
              {CUSTOMER_DATA.map((d, i) => (
                <p className="" key={i}>
                  {customer[d.value] || "--"}
                </p>
              ))}
            </Col>
          </Row>
        </ModalBody>
      </>
    </Modal>
  );
};

export default CustomerDetails;
