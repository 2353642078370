import axios from "axios";
import { set, isEmpty } from "lodash";
import { toast } from "react-toastify";
// import { getFirebaseBackend } from "./firebase_helper";
// import { api } from "../config";
const api = {};

// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const token = JSON.parse(sessionStorage.getItem("authUser"))
  ? JSON.parse(sessionStorage.getItem("authUser")).access_token
  : null;
if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem("session");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };

const tokenUpdated = async () => {
  let session = sessionStorage.getItem("session");
  session = session && JSON.parse(session);

  const refreshToken = session?.refresh_token;

  const token = session?.access_token;

  try {
    // return getFirebaseBackend()
    //   .supabase.auth.refreshSession()
    //   .then((res) => {
    //     if (res?.data?.session) {
    //       sessionStorage.setItem("session", JSON.stringify(res?.data?.session));
    //       return res?.data?.session?.access_token;
    //     }
    //   });
  } catch (error) {
    return error;
  }
};

export const fixNumbers = (str, removeSpaces) => {
  var persianNumbers = [
    /۰/g,
    /۱/g,
    /۲/g,
    /۳/g,
    /۴/g,
    /۵/g,
    /۶/g,
    /۷/g,
    /۸/g,
    /۹/g,
  ];
  var arabicNumbers = [
    /٠/g,
    /١/g,
    /٢/g,
    /٣/g,
    /٤/g,
    /٥/g,
    /٦/g,
    /٧/g,
    /٨/g,
    /٩/g,
  ];
  if (typeof str === "string") {
    for (var i = 0; i < 10; i++) {
      str = str
        .replace(persianNumbers[i], String(i))
        .replace(arabicNumbers[i], String(i));
    }
  }
  return removeSpaces ? str.trim().replace(/ /g, "") : str?.trim();
};

export default async function AppService({
  url,
  method,
  params,
  headers = {},
  form = null,
  // authToken = "",
}) {
  let token = null;
  set(headers, "Accept", "application/json");
  set(headers, "Content-Type", "application/json");
  set(headers, "Accept-Language", "en");
  if (typeof sessionStorage != "undefined") {
    let session = sessionStorage.getItem("session");
    session = session && JSON.parse(session);
    const expiryTime = session?.expires_at;
    token = session?.jwt;
    if (expiryTime) {
      // var decodedToken = jwt(authToken, {complete: true});
      var dateNow = new Date();

      if (parseInt(expiryTime) * 1000 < dateNow.getTime()) {
        token = await tokenUpdated();
      }
    }
  }

  set(headers, "Authorization", `${token}`);
  set(headers, "Credential", `${token}`);

  const reqBody = {
    method,
  };

  if (!isEmpty(params)) {
    reqBody.body = JSON.stringify(params);
  } else if (form) {
    reqBody.body = form;
  }

  const cleanedRequest = form
    ? reqBody
    : JSON.parse(fixNumbers(JSON.stringify(reqBody)));
  const cleanedUrl = fixNumbers(url);
  // const abortController = new AbortController();

  const timeout = setTimeout(() => {
    // abortController.abort();
  }, 20000);

  return fetch(
    cleanedUrl,
    form
      ? {
          ...reqBody,
          headers: {
            Authorization: `${token}`,
          },
          //  sign  al: abortController.signal
        }
      : {
          ...cleanedRequest,
          headers,
          // signal: abortController.signal,
        }
  )
    .then(async (response) => {
      clearTimeout(timeout);
      console.log("status", response?.status);
      if (!token && response?.status == 401) {
        toast.error("Invalid Credentials.");
        const error = await response?.json();
        throw new Error(JSON.stringify(error) + "");
      }
      if (token != null && response?.status == 401) {
        //Unautherized
        toast.error("Unautherized");
        const error = await response?.json();
        sessionStorage.removeItem("session");
        sessionStorage.removeItem("authUser");
        setTimeout(() => {
          window.location.replace("/login");
        }, 2000);
        throw new Error(JSON.stringify(error) + "");
      } else if (response?.status == 404) {
        toast.error("Not Found");
        const error = await response?.json();
        throw new Error(JSON.stringify(error) + "");
      } else if (response?.status < 200 || response?.status >= 300) {
        const error = await response?.json();
        toast.error(error?.error);
        throw new Error(JSON.stringify(error) + "");
      }
      return response.json();
    })
    .then((data) => {
      return {
        result: "ok",
        data,
        error: null,
        authorized: true,
      };
    })
    .catch((error) => {
      throw new Error(error);
    });
}
