//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
} from "./reducer";

export const loginUser = (user, history) => async (dispatch) => {
  // try {
  //   let fireBaseBackend = getFirebaseBackend();
  //   const response = await fireBaseBackend.loginUser(user.email, user.password);
  //   if (response) {
  //     if (response) {
  //       dispatch(loginSuccess(response));
  //       let selectedOrg = sessionStorage.getItem("organization")
  //         ? JSON.parse(sessionStorage.getItem("organization"))
  //         : [];

  //       if (selectedOrg?.userId !== response?.id) {
  //         sessionStorage.removeItem("organization");
  //         dispatch(
  //           setOrganizationGlobally({ userId: null, organization: null,uniqueName:null })
  //         );
  //         history("/organizations?checkOrg=true");
  //       } else {
  //         if (selectedOrg?.organization?.id) {
  //           dispatch(setOrganizationGlobally(selectedOrg));
  //           history("/contacts");
  //         } else {
  //           history("/organizations?checkOrg=true");
  //         }
  //       }
  //     } else {
  //       dispatch(apiError(response));
  //     }
  //   }
  // } catch (error) {
  //   dispatch(apiError(error));
  // }
};

export const logoutUser = () => async (dispatch) => {
  // try {
  //   sessionStorage.removeItem("authUser");
  //   let fireBaseBackend = getFirebaseBackend();
  //   const response = await fireBaseBackend.logout();
  //   dispatch(logoutUserSuccess(response));
  //   dispatch(loginSuccess({}));
  // } catch (error) {
  //   dispatch(apiError(error));
  // }
};

export const socialLogin = (type, history) => async (dispatch) => {
  // try {
  //   let response;

  //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //     const fireBaseBackend = getFirebaseBackend();
  //     response = fireBaseBackend.socialLoginUser(type);
  //   }
  //   //  else {
  //   //   response = postSocialLogin(data);
  //   // }

  //   const socialdata = await response;
  //   if (socialdata) {
  //     // sessionStorage.setItem("authUser", JSON.stringify(response));
  //     dispatch(loginSuccess(response));
  //     history("/dashboard");
  //   }
  // } catch (error) {
  //   dispatch(apiError(error));
  // }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
