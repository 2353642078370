import React from "react";
import { Col, Button, Modal, ModalBody, Row } from "reactstrap";

const REQUEST_DATA = [
  { label: "ID", value: "id" },
  { label: "Owner Name", value: "ownerName" },
  { label: "Notice Date", value: "noticeDate" },
  { label: "Notice Fee", value: "noticeFee" },
  { label: "Customer ID", value: "requestID" },
  { label: "Model", value: "model" },
  { label: "Tow Amount", value: "towAmount" },
  { label: "Plate", value: "plate" },
  { label: "Auction Fee", value: "auctionFee" },
  { label: "Totaled", value: "totaled" },
  { label: "Watercraft", value: "watercraft" },
  { label: "Lien Fee", value: "lienFee" },
  { label: "Make", value: "make" },
  { label: "Style", value: "style" },
  { label: "Year", value: "year" },
  { label: "VIN", value: "vin" },
  { label: "Heavy", value: "heavy" },
  { label: "Import Date", value: "importDate" },
  { label: "Reference ID", value: "referenceID" },
  { label: "Owner Address", value: "ownerAddress" },
  { label: "Storage Amount", value: "storageAmount" },
  { label: "Auction Date", value: "auctionDate" },
  { label: "Tow Reason", value: "towReason" },
  { label: "Tow Date", value: "towDate" },
  { label: "Lien Length", value: "lienLength" },
  { label: "Storage Rate", value: "storageRate" },
  { label: "State", value: "state" },
  {
    label: "Documents",
    value: "documents",
    formatter: (value) => {
      return (
        <>
          {value?.map?.((d) => (
            <p>{d}</p>
          ))}
        </>
      );
    },
  },
];

const RequestDetails = ({
  request = {},
  isOpen,
  setIsOpen,
  onEditCustomer,
  permissions,
}) => {
  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen();
      }}
    >
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <h5 className="modal-title">Request Details</h5>
          <Button
            type="button"
            onClick={() => onClose()}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody className="pt-0">
          {permissions?.requests?.includes("update") && (
            <div className="d-flex justify-content-end">
              <Button
                className="ml-auto"
                color="success"
                onClick={onEditCustomer}
              >
                Edit Request
              </Button>
            </div>
          )}
          <Row>
            <Col xs={4} sm={4} className="">
              {REQUEST_DATA.map((d, i) => (
                <p key={i} className="font-weight-bold">
                  {d.label}
                </p>
              ))}
            </Col>
            <Col xs={8} sm={8}>
              {REQUEST_DATA.map((d, i) =>
                d?.formatter ? (
                  d.formatter(request[d.value])
                ) : (
                  <p className="" key={i}>
                    {request[d.value] || "--"}
                  </p>
                )
              )}
            </Col>
          </Row>
        </ModalBody>
      </>
    </Modal>
  );
};

export default RequestDetails;
