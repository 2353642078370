import React from "react";
import Select from "react-select";
import { Label } from "reactstrap";

const ReactSelect = ({
  labelName = "",
  options = [],
  optionLabel = "label",
  optionValue = "value",
  formikObj = {},
  id = "",
  placeholder = "",
  isReq = false,
  isMulti = false,
}) => {
  return (
    <div className="react-select-formik">
      <Label htmlFor={id} className="form-label">
        {labelName}
        {isReq && <span className="text-danger">*</span>}
      </Label>
      <Select
        options={options}
        getOptionLabel={(option) => option?.[optionLabel]}
        getOptionValue={(option) => option?.[optionValue]}
        placeholder={placeholder}
        value={
          isMulti
            ? formikObj?.values?.[id]
            : options?.find?.(
                (obj) => obj?.[optionValue] === formikObj.values?.[id]
              )
        }
        name={id}
        id={id}
        className="form-control"
        onChange={(value) => {
          if (isMulti) {
            formikObj?.setFieldValue(id, value);
          } else {
            formikObj?.setFieldValue(id, value?.[optionValue]);
          }
        }}
        onBlur={formikObj.handleBlur}
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: "#fff",
            borderColor: formikObj.errors[id] ? "red" : styles.borderColor,
            "&:hover": {
              borderColor: formikObj.errors[id]
                ? "red"
                : styles["&:hover"].borderColor,
            },
          }),

          valueContainer: (base) => ({
            ...base,
            minHeight: "40px",
            backgroundColor: "#fff",
          }),
        }}
        isMulti={isMulti}
      />
      {formikObj.touched[id] && formikObj.errors[id] ? (
        <span className="error">{formikObj.errors[id]}</span>
      ) : null}
    </div>
  );
};

export default ReactSelect;
