import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "../Components/Common/withRouter";

//import Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
// import RightSidebar from '../Components/Common/RightSidebar';

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const Layout = (props) => {
  const [headerClass, setHeaderClass] = useState("");
  const dispatch = useDispatch();

  const { layoutType, layoutModeType } = useSelector((state) => ({
    layoutType: state?.Layout?.layoutType,
    leftSidebarType: state?.Layout?.leftSidebarType,
    layoutModeType: state?.Layout?.layoutModeType,
    layoutWidthType: state?.Layout?.layoutWidthType,
    layoutPositionType: state?.Layout?.layoutPositionType,
    topbarThemeType: state?.Layout?.topbarThemeType,
    // leftsidbarSizeType: state?.Layout?.leftsidbarSizeType,
    leftSidebarViewType: "default",
    leftSidebarImageType: state?.Layout?.leftSidebarImageType,
    sidebarVisibilitytype: "light",
  }));

  useEffect(() => {
    if (document.documentElement) {
      document.documentElement.setAttribute("data-layout-style", "detached");
      document.documentElement.setAttribute("data-sidebar", "light");
      document.documentElement.setAttribute("data-layout-mode", "light");
      document.documentElement.setAttribute("data-layout-width", "fluid");
      document.documentElement.setAttribute("data-layout-position", "fixed");
      document.documentElement.setAttribute("data-topbar", "dark");
      document.documentElement.setAttribute("data-layout", "vertical");
      document.documentElement.setAttribute("data-sidebar-image", "none");
      document.documentElement.setAttribute("data-sidebar-visibility", "show");
      document.documentElement.setAttribute("data-preloader", "enable");
      document.documentElement.setAttribute("data-sidebar", "dark");
      document.documentElement.setAttribute("data-layout-position", "fixed");
      document.documentElement.setAttribute("data-layout-style", "default");
      document.documentElement.setAttribute("data-sidebar-size", "sm");
    }
  }, []);
  // Inside your component

  /*
    layout settings
    */

  /*
    call dark/light mode
    */
  const onChangeLayoutMode = (value) => {
    // if (changeLayoutMode) {
    //     dispatch(changeLayoutMode(value));
    // }
  };

  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  }

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          headerClass={headerClass}
          layoutModeType={layoutModeType}
          onChangeLayoutMode={onChangeLayoutMode}
        />
        <Sidebar layoutType={layoutType} />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
      {/* <RightSidebar /> */}
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
};

export default withRouter(Layout);
