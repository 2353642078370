import React from "react";
import withRouter from "../../Components/Common/withRouter";

const ParticlesAuth = ({ children }) => {
  return (
    <React.Fragment>
      <div className="auth-page-wrapper pt-5">
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
          <div className="bg-overlay"></div>
        </div>

        {/* pass the children */}
        {children}

        {/* <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0 text-muted">{new Date().getFullYear()} &copy; Ticket Jump (a <a href="https://notalltalk.com/" target="_blank">Not All Talk</a> company)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer> */}
      </div>
    </React.Fragment>
  );
};

export default withRouter(ParticlesAuth);
