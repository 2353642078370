import React, { useState } from "react";
import {
  Col,
  Button,
  Modal,
  ModalBody,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import { updateUserPasswordAPI } from "../../helpers/backend_helper";

const UpdatePasswordModal = ({ isOpen, setIsOpen }) => {
  const User = JSON.parse(sessionStorage.getItem("user"));

  const [isLoading, setIsLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);

  const onSubmit = async (values) => {
    setIsLoading(true);
    const data = { ...values };
    data.userID = User?.username;
    try {
      await updateUserPasswordAPI(data);
      setIsOpen(false);
    } catch (err) {
      setIsLoading(false);
      console.log("error", err);
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = (formik) => {
    formik.resetForm();
    // resetAsset(null);
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen();
      }}
    >
      {/* <ModalHeader> */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          newPassword: "",
        }}
        validationSchema={Yup.object({
          newPassword: Yup.string().required("Please Enter Password"),
        })}
        onSubmit={async (values) => {
          onSubmit(values);
        }}
      >
        {(validation) => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <h5 className="modal-title">Update User Password</h5>
              <Button
                type="button"
                onClick={() => onClose(validation)}
                className="btn-close"
                aria-label="Close"
              ></Button>
            </div>
            {/* </ModalHeader> */}
            <ModalBody>
              <Form>
                <div className="row g-3">
                  <Col xxl={12}>
                    <div className="">
                      <Label className="form-label" htmlFor="newPassword">
                        Password<span className="text-danger">*</span>
                      </Label>
                      <div className="position-relative auth-pass-inputgroup">
                        <Input
                          name="newPassword"
                          id="newPassword"
                          value={validation.values.newPassword || ""}
                          type={passwordShow ? "text" : "password"}
                          className="form-control pe-5"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.newPassword &&
                            validation.errors.newPassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.newPassword &&
                        validation.errors.newPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.newPassword}
                          </FormFeedback>
                        ) : null}
                        <button
                          onClick={() => setPasswordShow(!passwordShow)}
                          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          type="button"
                          id="password-addon"
                        >
                          <i className="ri-eye-fill align-middle"></i>
                        </button>
                      </div>
                    </div>
                  </Col>

                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-end">
                      <Button color="light" onClick={() => onClose(validation)}>
                        Close
                      </Button>
                      <Button color="primary" disabled={isLoading}>
                        {isLoading ? "Updating..." : "Update"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </ModalBody>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default UpdatePasswordModal;
