import React from "react";
import { Navigate } from "react-router-dom";
import BasicSignIn from "../Pages/Signin/Signin";
import Transactions from "../Pages/Transaction";
import Users from "../Pages/Users";
import Customers from "../Pages/Customers";
import Dashboard from "../Pages/Dashboard";
import Requests from "../Pages/Requests";

const authProtectedRoutes = [
  {
    path: "/customers",
    component: <Customers />,
  },
  {
    path: "/users",
    component: <Users />,
  },
  {
    path: "/",
    component: <Requests />,
  },
  { path: "*", component: <Navigate to="/" /> },
];

const publicRoutes = [
  // Authentication Page

  {
    path: "/login",
    component: <BasicSignIn />,
  },
 
];

export { authProtectedRoutes, publicRoutes };
