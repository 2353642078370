import React, { useEffect, useState, useMemo } from "react";

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  Spinner,
  Label,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import TableContainer from "../../Components/Common/TableContainer";
import {
  deleteCustomer,
  deleteRequestById,
  filterRequests,
  getAllCustomers,
  getRequests,
  getRequestTowReasons,
  lockRequestById,
  printRequestFormApi,
  unLockRequestById,
  unlockRequestById,
} from "../../helpers/backend_helper";
import Pagination from "rc-pagination";
import RequestDetails from "./RequestDetails";
import RequestModal from "./RequestModal";
import { toast } from "react-toastify";
import FilterModal from "./FilterModal";

const LIMIT = 20;
let searchTimeout = null;

const Requests = () => {
  const navigate = useNavigate();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const User = JSON.parse(sessionStorage.getItem("user"));
  const ITEMS_PER_PAGE = 20;
  const [addCustomerModal, setAddCustomerModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeRequestIndex, setActiveRequestIndex] = useState(-1);
  const [detailsModal, setDetailsModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [towReasons, setTowReasons] = useState([]);

  const customPaginate = (data, itemsPerPage, pageNumber) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedItems = data.slice(startIndex, endIndex);
    return paginatedItems;
  };

  const loadTowReasons = async () => {
    try {
      const res = await getRequestTowReasons();
      setTowReasons(res);
    } catch (err) {
      console.log("err", err);
    }
  };

  const printRequestForm = async () => {
    try {
      const res = await printRequestFormApi();
      console.log("res", res);
    } catch (err) {
      console.log("err", err);
    }
  };

  const loadRequestData = async (loading = false) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
      const res = await getRequests();
      setData(res);
      setRenderData(customPaginate(res, ITEMS_PER_PAGE, 1));
      setIsLoading(false);
      setIsFilterApplied(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const filterRequestsData = async ({ searchBy, searchValue }) => {
    const data = {};
    if (searchBy === "vin") {
      data.vin = searchValue;
    } else {
      data.plate = searchValue;
    }
    try {
      const res = await filterRequests(data);
      setData(res);
      setRenderData(customPaginate(res, ITEMS_PER_PAGE, 1));
      setFilterModal(false);
      setIsFilterApplied(true);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (!permissions?.requests?.includes("read")) {
      navigate("/");
    }
    loadRequestData(true);
    loadTowReasons();
  }, []);

  const onPageChange = (current, pageSize) => {
    setCurrentPage(current);
    setRenderData(customPaginate(data, ITEMS_PER_PAGE, current));
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "Owner Name",
        accessor: "ownerName",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "VIN",
        accessor: "vin",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "Import Date",
        accessor: "importDate",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "Auction Date",
        accessor: "auctionDate",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "Tow Date",
        accessor: "towDate",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "Make",
        accessor: "make",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "Model",
        accessor: "model",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "Plate",
        accessor: "plate",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "",
        Cell: (cellProps) => {
          const [deleting, setDeleting] = useState(false);
          const [locking, setLocking] = useState(false);
          const handleDetails = () => {
            setActiveRequestIndex(
              (currentPage - 1) * LIMIT + cellProps?.row?.index
            );
            setDetailsModal(true);
          };

          const handleDelete = async (e) => {
            e.stopPropagation();
            setDeleting(true);
            try {
              await deleteRequestById(cellProps?.row?.original?.id);
              const tempData = [...data];
              tempData.splice(cellProps?.row?.index, 1);
              setData(tempData);
              setRenderData(
                customPaginate(tempData, ITEMS_PER_PAGE, currentPage)
              );
            } catch (err) {
              console.log(err);
            } finally {
              setDeleting(false);
            }
          };

          const handleLockRequest = async (e) => {
            e.stopPropagation();
            setLocking(true);
            try {
              if (cellProps?.row?.original?.lockedBy) {
                await unLockRequestById(cellProps?.row?.original?.id);
              } else {
                await lockRequestById(cellProps?.row?.original?.id);
              }
              await loadRequestData();
            } catch (err) {
              console.log(err);
            } finally {
              setLocking(false);
            }
          };

          return (
            <div className="d-flex justify-content-end">
              {!cellProps?.row?.original?.lockedBy && (
                <Button
                  disabled={locking}
                  onClick={handleLockRequest}
                  color="secondary"
                  className="me-2"
                  outline
                >
                  {cellProps?.row?.original?.lockedBy
                    ? locking
                      ? "Unlocking..."
                      : "Unlock"
                    : locking
                    ? "Locking..."
                    : "Lock"}
                </Button>
              )}
              <Button
                onClick={handleDetails}
                color="primary"
                className="me-2"
                outline
              >
                Details
              </Button>
              {permissions?.requests?.includes("delete") && (
                <Button
                  disabled={deleting}
                  onClick={handleDelete}
                  color="danger"
                  outline
                >
                  {deleting ? "Deleting..." : "Delete"}
                </Button>
              )}
            </div>
          );
        },
        accessor: "mcustcmt",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const onEditCustomer = () => {
    setDetailsModal(false);
    setAddCustomerModal(true);
  };

  document.title = "Requests | ALW";

  return (
    <React.Fragment>
      {addCustomerModal && (
        <RequestModal
          request={activeRequestIndex > -1 ? data[activeRequestIndex] : {}}
          isOpen={addCustomerModal}
          setIsOpen={setAddCustomerModal}
          setActiveRequestIndex={setActiveRequestIndex}
          loadRequestData={loadRequestData}
          towReasons={towReasons}
        />
      )}
      <RequestDetails
        isOpen={detailsModal}
        request={activeRequestIndex > -1 ? data[activeRequestIndex] : {}}
        setIsOpen={setDetailsModal}
        onEditCustomer={onEditCustomer}
        permissions={permissions}
      />
      <FilterModal
        isOpen={filterModal}
        setIsOpen={setFilterModal}
        filterRequestsData={filterRequestsData}
      />
      <div className="page-content">
        <Container fluid>
          <div className="customer-table-transition">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-2">Requests</h4>
              <div className="d-flex gap-3">
                <Button
                  color="info"
                  onClick={() => {
                    if (isFilterApplied) {
                      loadRequestData(true);
                    } else {
                      setFilterModal(true);
                    }
                  }}
                  disabled={isLoading}
                >
                  <i class="ri-equalizer-fill me-2"></i>
                  {isFilterApplied ? "Clear" : "Filter"}
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    printRequestForm();
                  }}
                >
                  Print Completed Request
                </Button>
                {permissions?.requests?.includes("create") && (
                  <div className="page-title-right">
                    <Button
                      color="success"
                      onClick={() => {
                        setAddCustomerModal(true);
                        setActiveRequestIndex(-1);
                      }}
                    >
                      Add Request
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Row className="customer-row mx-0">
            <Col xxl={12} className="customer-table-transition">
              <Card id="contactList">
                <CardBody className="pt-0">
                  {isLoading ? (
                    <div className="py-5 d-flex justify-content-center">
                      <Spinner color="primary" />
                    </div>
                  ) : data?.length > 0 ? (
                    <div className="table-responsive">
                      <TableContainer
                        columns={columns}
                        data={renderData || []}
                        isBordered={false}
                        customPageSize={1500}
                        className="custom-header-css table align-middle table-nowrap"
                        tableClassName="table-centered align-middle table-nowrap mb-0"
                        theadClassName="text-muted table-light"
                        SearchPlaceholder="Search..."
                        onRowClick={(row) => {
                          setActiveRequestIndex(
                            (currentPage - 1) * LIMIT + row?.index
                          );
                          setDetailsModal(true);
                        }}
                        rowClickable
                        // customSortByCb={onCustomSortBy}
                        // customSortBy={sortBy}
                        manualSort
                        // divClass="table-responsive table-card mb-3"
                        // tableClass="align-middle table-nowrap"
                        // theadClass="table-light"
                      />
                      <Row className="gap-0 justify-content-md-between mx-0 justify-content-center align-items-center py-2">
                        <Col className="col-md-auto ps-0">
                          Showing{" "}
                          <strong> {(currentPage - 1) * LIMIT + 1}</strong> to{" "}
                          <strong>
                            {" "}
                            {(currentPage - 1) * LIMIT + LIMIT > data?.length
                              ? data?.length
                              : (currentPage - 1) * LIMIT + LIMIT}{" "}
                          </strong>
                          of <strong> {data?.length} </strong> results
                        </Col>
                        <Col
                          xs={6}
                          md={9}
                          className="d-flex justify-content-end  align-items-center py-2"
                        >
                          <Pagination
                            current={currentPage}
                            total={data?.length}
                            defaultPageSize={ITEMS_PER_PAGE}
                            onChange={onPageChange}
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>Sorry! No Result Found</h5>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Requests;
