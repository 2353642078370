import React, { useEffect, useState, useMemo } from "react";

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  Spinner,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import TableContainer from "../../Components/Common/TableContainer";
import { deleteUserAPI, getAllUsers } from "../../helpers/backend_helper";
import AddUser from "./AddUser";
// import moment from "moment";

const Users = () => {
  const navigate = useNavigate();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const [addUserModal, setAddUserModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(-1);
  const [data, setData] = useState([]);

  useEffect(() => {
    loadCustomerData();
  }, []);

  useEffect(() => {
    if (!addUserModal) {
      setSelectedUser(-1);
    }
  }, [addUserModal]);

  const loadCustomerData = async () => {
    try {
      setIsLoading(true);
      const data = await getAllUsers();
      setIsLoading(false);
      setData(data);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const deleteUser = async (user, index) => {
    try {
      const tempData = [...data];
      tempData.splice(index, 1);
      setData(tempData);
      await deleteUserAPI(user);
    } catch (err) {
      console.log(err);
    }
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "Username",

        accessor: "username",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "Role",
        accessor: "role",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "Company",
        accessor: "company",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },

      {
        Header: "Email",
        accessor: "email",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
      {
        Header: "",
        Cell: (cellProps) => {
          return (
            <div className="d-flex w-100 justify-content-center">
              {permissions?.users?.includes("update") && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedUser(cellProps.row.index);
                    setAddUserModal(true);
                  }}
                  color="secondary"
                  className="me-2"
                  outline
                >
                  Edit
                </Button>
              )}
              {permissions?.users?.includes("delete") && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteUser(
                      cellProps.row.original?.username,
                      cellProps.row.index
                    );
                  }}
                  color="danger"
                  outline
                >
                  Delete
                </Button>
              )}
            </div>
          );
        },
        accessor: "edit",
        disableFilters: false,
        filterable: true,
        disableSortBy: true,
      },
    ],
    []
  );

  document.title = "Users | ALW";

  return (
    <React.Fragment>
      <AddUser
        isOpen={addUserModal}
        setIsOpen={setAddUserModal}
        user={selectedUser > -1 ? data[selectedUser] : null}
        loadCustomerData={loadCustomerData}
      />
      <div className="page-content">
        <Container fluid>
          <Col xxl={12} className="customer-table-transition">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-2">Users</h4>

              <div className="page-title-right">
                {permissions?.users?.includes("create") && (
                  <Button
                    color="success"
                    onClick={() => {
                      setAddUserModal(true);
                    }}
                  >
                    Add User
                  </Button>
                )}
              </div>
            </div>
          </Col>
          <Row className="customer-row">
            <Col xxl={12} className="customer-table-transition">
              <Card id="contactList">
                <CardBody className="pt-0">
                  {isLoading ? (
                    <div className="py-5 d-flex justify-content-center">
                      <Spinner color="primary" />
                    </div>
                  ) : data?.length > 0 ? (
                    <div className="table-responsive">
                      <TableContainer
                        columns={columns}
                        data={data || []}
                        isBordered={false}
                        customPageSize={1500}
                        className="custom-header-css table align-middle table-nowrap"
                        tableClassName="table-centered align-middle table-nowrap mb-0"
                        theadClassName="text-muted table-light"
                        SearchPlaceholder="Search..."
                        rowClickable
                        onRowClick={(row) => {
                          setSelectedUser(row?.index);
                          setAddUserModal(true);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="py-4 text-center">
                      <div className="mt-4">
                        <h5>Sorry! No Result Found</h5>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
