import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import UpdatePasswordModal from "./UpdatePasswordModal";

const ProfileDropdown = ({ userName, firstName, lastName, organization }) => {
  const navigate = useNavigate();
  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = useState(false);

  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <React.Fragment>
      <UpdatePasswordModal
        isOpen={updatePasswordModalOpen}
        setIsOpen={setUpdatePasswordModalOpen}
      />
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <div
              className="rounded-circle header-profile-user d-flex align-items-center justify-content-center"
              style={{ background: "#fcba40" }}
            >
              <strong>
                {firstName?.charAt?.(0)}
                {lastName?.charAt?.(0)}
              </strong>
            </div>
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {userName || ""}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {organization?.name || "--"}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => setUpdatePasswordModalOpen(true)}>
            {/* <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "} */}
            <span className="align-middle" data-key="t-logout">
              Update Password
            </span>
          </DropdownItem>
          <DropdownItem onClick={handleLogout}>
            {/* <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "} */}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
