import React, { useState } from "react";
import {
 Col,
 Button,
 Modal,
 ModalBody,
 Input,
 Label,
 FormFeedback,
} from "reactstrap";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import ReactSelect from "react-select";

const FilterModal = ({
  isOpen,
  setIsOpen,
  filterRequestsData
}) => {
 const [isLoading, setIsLoading] = useState(false);

 const onSubmit = async (values) => {
  try {
   setIsLoading(true)
   await filterRequestsData(values);
   // setIsOpen(false);
   setIsLoading(false);
  } catch(err) {
   console.log('err', err)
  }

 }

  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setIsOpen();
      }}
    >
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <h5 className="modal-title">Filter Requests</h5>
          <Button
            type="button"
            onClick={() => onClose()}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
   
        <Formik
        enableReinitialize={true}
        initialValues={{
          // customerID: "", 
          searchBy: "vin",
          searchValue: ""
        }}
        validationSchema={Yup.object({
        //  customerID: Yup.string().required("Customer ID is required"),
         searchValue: Yup.string().required("Search Value is required"),
    
        })}
        onSubmit={onSubmit}
      >
        {(validation) => (
          <>
            {/* </ModalHeader> */}
            <ModalBody>
              <Form>
                <div className="row g-3">
              
                  {/* <Col sm={12} >
                    <div>
                      <Label htmlFor="customerID" className="form-label">
                        Customer ID<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="customerID"
                        type="text"
                        className="form-control"
                        placeholder="Enter Customer ID"
                        id="customerID"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.customerID || ""}
                        invalid={
                          validation.touched.customerID &&
                          validation.errors.customerID
                            ? true
                            : false
                        }
                      />
                      {validation.touched.customerID &&
                      validation.errors.customerID ? (
                        <FormFeedback type="invalid">
                          {validation.errors.customerID}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col> */}
                  <Col sm={12} >
                    <div>
                    <Label htmlFor="ownerName" className="form-label">
                        Search By
                      </Label>
                      <ReactSelect
                        labelName="Totaled"
                        options={[
                          { label: "VIN", value: "vin" },
                          { label: "Plate", value: "plate" },
                        ]}
                        optionLabel="label"
                        optionValue="value"
                        formikObj={validation}
                        id="searchBy"
                        placeholder="Select"
                        defaultValue={{
                         label: "VIN",
                         value:"vin"
                        }}
                        onChange={(opt) => {
                         validation.setFieldValue("searchBy", opt.value)
                        }}
                      />

                      {validation.touched.searchBy &&
                      validation.errors.searchBy ? (
                        <FormFeedback type="invalid">
                          {validation.errors.searchBy}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
           
                  <Col sm={12}>
                    <div>
                      <Label htmlFor="searchValue" className="form-label">
                        {validation.values.searchBy === "vin" ? "VIN" : "Plate"}<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="searchValue"
                        type="text"
                        className="form-control"
                        placeholder={`Enter ${validation.values.searchBy === "vin" ? "VIN" : "Plate"}`}
                        id="searchValue"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.searchValue || ""}
                        invalid={
                          validation.touched.searchValue &&
                          validation.errors.searchValue
                            ? true
                            : false
                        }
                      />
                      {validation.touched.searchValue &&
                      validation.errors.searchValue ? (
                        <FormFeedback type="invalid">
                          {validation.errors.searchValue}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
         

              

                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-end">
                      <Button color="light" onClick={() => onClose(validation)}>
                        Close
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isLoading}
                      >
                        {isLoading
                          ? "Filtering..."
                          : "Filter"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </ModalBody>
          </>
        )}
      </Formik>
      </>
    </Modal>
  );
};

export default FilterModal;
