export const API_URL =  "https://autolienworks.com"; // "http://localhost:8080/https://autolienworks.com"; 
export const LOGIN = "/login";
export const USERS = "/users";
export const CUSTOMERS = "/customers";
export const DASHBOARD = "/dashboard";
export const SETTINGS = "/settings";
export const REQUESTS = "/requests";
export const DOCUMENTS = "/documents";
export const REQUEST_FORM = "/documents/completed";
export const PERMISSIONS = "/permissions";

