import { combineReducers } from "redux";


import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


// Redux persist configiration
const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const rootReducer = combineReducers({

});

export default persistReducer(persistConfig, rootReducer);
